import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Explorer from "../components/explorer"
import HeroTitleBlock from "../components/hero-title-block"
import DiscoverMore from "../components/discover-more"
import Accordion from "../components/accordion"
import ModifiedHelmet from '../components/modified-helmet'
export default ({ data: { allDatoCmsSubcategory, datoCmsCategory: {title, landingPageHeader, landingPageDiscoverMore, seoMetaTags, discoverMoreFooter, accordion} } }) => {
  let explorer = {};
  explorer.tabs = [
    {title: `All ${title}`, visibleItems: 12, list: { __typename: "DatoCmsDynamicList", scopes: [{__typename: "DatoCmsCategory", title: title }] }}
  ]
  const filters = allDatoCmsSubcategory.edges.map(edge => edge.node)
  if (filters.length !== 0) {
    explorer.tabs.push({title: "Filter category", list: { __typename: "FilterDropdown", filters: filters, scopes: [{__typename: "DatoCmsCategory", title: title }] } })
  }

  return (
    <Layout>
      <ModifiedHelmet seo={seoMetaTags} />
      {landingPageHeader && (
        <HeroTitleBlock widget={landingPageHeader} />
      )}
      {landingPageDiscoverMore && (
        <DiscoverMore widget={landingPageDiscoverMore} />
      )}
      <Explorer widget={explorer} />
      {accordion && (
        <Accordion widget={accordion} />
      )}
      {discoverMoreFooter && (
        <DiscoverMore widget={discoverMoreFooter} />
      )}

    </Layout>
  )
}

export const query = graphql`
  query CategoryQuery($id: String!, $title: String!) {
    datoCmsCategory(id: { eq: $id }) {
      title
      landingPageHeader {
        ...HeroTitleBlock
      }
      landingPageDiscoverMore {
        ...DiscoverMore
      }
      discoverMoreFooter {
        ...DiscoverMore
      }
      accordion {
        ...Accordion
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsSubcategory(filter: {category: {eq: $title}}) {
      edges {
        node {
          __typename
          id
          title
          slug
          category
        }
      }
    }
  }
`
