import React from "react"
export default ({widget: {title, list}}) => (
  <section className="uk-section accordion-widget">
    <div className="uk-container uk-container-small">
      {title && (
        <h3 className="uk-text-secondary uk-text-center uk-text-uppercase">{title}</h3>
      )}
      <ul uk-accordion="multiple: true">
        {
          list && list.map((item, i) => (
            <li className={item.expanded ? "uk-open" : ""} key={i}>
              <a className="uk-accordion-title uk-text-primary uk-background-muted uk-padding-small" href={`#${item.title}`}>{item.title}</a>
              <div className="uk-accordion-content uk-padding-small" dangerouslySetInnerHTML={{__html: item.body }}></div>
            </li>
          ))
        }
      </ul>
    </div>
  </section>
)
